import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import styled from '@emotion/styled';
import config from '../../config/website';

const SocialMedia = styled.div`
  a {
    font-size: 1.25rem;
    line-height: 20px;
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
`;

const HiddenText = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  :focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
  }
`;

const SocialMediaLinks = () => (
  <SocialMedia>
    <a href={config.linkedinUrl} target="_blank" rel="noopener noreferrer">
      <FaLinkedin />
      <HiddenText>LinkedIn</HiddenText>
    </a>
    <a href={config.githubUrl} target="_blank" rel="noopener noreferrer">
      <FaGithub />
      <HiddenText>GitHub</HiddenText>
    </a>
    <a href={config.emailAddress} target="_blank" rel="noopener noreferrer">
      <FaEnvelope />
      <HiddenText>E-Mail</HiddenText>
    </a>
  </SocialMedia>
);

export default SocialMediaLinks;
