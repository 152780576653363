import React from 'react';
import styled from '@emotion/styled';
import config from '../../config/website';
import SocialMediaLinks from './SocialMediaLinks';

const Wrapper = styled.footer`
  margin: 5rem 0;
  padding: 1rem ${props => props.theme.spacer.horizontal};
  padding-bottom: 3rem;
  text-align: center;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.link_color};
    : hover {
      color: ${props => props.theme.colors.link_color_hover};
    }
  }
`;

const HiddenHeader = styled.h1`
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const Footer = () => (
  <Wrapper>
    <HiddenHeader>Footer</HiddenHeader>
    <SocialMediaLinks />
    <span>Copyright &copy; 2020. All right reserved.</span>
    <br />
    <span>Powered by </span>
    <a href="https://www.gatsbyjs.org/">Gatsby.js. </a>
  </Wrapper>
);

export default Footer;
