import React from 'react';
import { Link, withPrefix } from 'gatsby';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import styled from '@emotion/styled';
import { cx, css } from '@emotion/core';
import config from '../../config/website';
import theme from '../../config/theme';
import SocialMediaLinks from './SocialMediaLinks';

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  height: ${props => props.theme.toolbar.height};
  background-color: ${props => props.theme.brand.toolbar};
  z-index: 12;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
  overflow: hidden;

  a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    transition: all 0.333s ease-in-out;
    &:hover {
      color: rgb(8, 126,139);
    }
    &:focus {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const CenterToolbar = styled.nav`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  max-width:  ${props => props.theme.breakpoints.l};
  margin: 0 auto;
`;

const Name = styled.h1`
  font-size: 1.5em;

  a {
    font-size: 23px;
    font-family: ${`${config.headerFontFamily}, sans-serif`};
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &.active {
      color: ${theme.brand.primary} !important;
    }
  }
  font-weight: 700;
  transform: translateY(1px);
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
`;

const FlexEnd = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const NavLinks = styled.ul`
  padding-left: 16px;
  a {
    padding-left: 4px;
    padding-right: 4px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-left: auto;
  }
  margin-bottom: -5px;
  margin: 0;
`;

const HideOnMobile = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

const NavLinkItem = styled.li`
  display:inline;

  a.active {
    color: ${theme.brand.primary} !important;
  }
`;

const Navigation = () => (
  <Wrapper>
    <CenterToolbar aria-labelledby="nav-heading">
      <Name id="nav-heading">
        <Link
          to="/"
          activeClassName="active"
        >
          {config.siteTitle}
        </Link>
      </Name>
      <NavLinks>
        <NavLinkItem>
          <Link
            to="/work/"
            activeClassName="active"
          >
            Work
          </Link>
        </NavLinkItem>
        <NavLinkItem>
          <Link
            to="/projects/"
            activeClassName="active"
          >
            Projects
          </Link>
        </NavLinkItem>
        <NavLinkItem>
          <Link
            to="/about/"
            activeClassName="active"
          >
            About
          </Link>
        </NavLinkItem>
        <NavLinkItem>
          <a
            href={withPrefix('/Luke-Lapresi-Resume.pdf')}
          >
            Resume
          </a>
        </NavLinkItem>
      </NavLinks>
      <FlexEnd>
        <HideOnMobile>
          <SocialMediaLinks />
        </HideOnMobile>
      </FlexEnd>
    </CenterToolbar>
  </Wrapper>
);

export default Navigation;
