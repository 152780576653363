import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const HeroBannerSpace = styled.div`
  position: relative;
  height: ${props => props.theme.homepageBanner.height.medium}vw;
  background-color: #0d1417;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  color: white;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    height: ${props => props.theme.homepageBanner.height.small}vw;
  }
`;

const FaceImg = styled(Img)`
  width: ${props => props.theme.homepageBanner.height.medium}vw;
  height: ${props => props.theme.homepageBanner.height.medium}vw;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: ${props => props.theme.homepageBanner.height.small}vw;
    height: ${props => props.theme.homepageBanner.height.small}vw;
  
  }
  background-size: cover;
`;

const Face = () => {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "images/face.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }      
        }
      }
    `,
  );

  return (
    <FaceImg fluid={file.childImageSharp.fluid} />
  );
};

const Content = styled.div`
  width: ${props => 100 - props.theme.homepageBanner.height.medium}vw;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const BigText = styled.p`
  display: inline-block;
  font-size: 5vw;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 7vw;  
  }
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

// Font sized calced by 3.5*(.66^x)

const SkillLabel = styled.p`
  display: inline;
  font-weight: bold;
  padding: 0;
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const TextRow = styled(FlexRow)`  
  > * {
    font-size:  ${props => props.fontSize};
    color: ${props => props.color};
    margin: 0;
    line-height: calc(${props => props.fontSize} + 0.3vw);
    padding-bottom: 6px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: ${props => props.hideMobile ? 'none' : 'inherit'};
  }
`;

const TopRow = styled(TextRow)`  
  > * {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size:  ${props => props.fontSizeMobile};
    }
    color: ${props => props.theme.brand.primary};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;


const BaseRow = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  margin-bottom: 12px;
`;

const FlexFill = styled.div`
  flex: 1;
`;
const FlexVerticalCenter = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const HeroBanner = () => (

  <HeroBannerSpace>
    <Face />
    <Content>
      <FlexFill>
        <FlexVerticalCenter>
          <BigText>
            <strong>LUKE LAPRESI</strong>
          </BigText>
        </FlexVerticalCenter>
      </FlexFill>
      <BaseRow>
        <FlexRow>
          <TopRow fontSize="3.5vw" fontSizeMobile="5vw">
            <SkillLabel>UX</SkillLabel>
            <SkillLabel>Full-Stack</SkillLabel>
            <SkillLabel>Interactive</SkillLabel>
          </TopRow>
        </FlexRow>
        <TextRow fontSize="2.31vw" color="rgba(255, 255, 255, 0.7)" hideMobile>
          <SkillLabel aria-hidden="true">Developer</SkillLabel>
          <SkillLabel aria-hidden="true">Programmer</SkillLabel>
          <SkillLabel aria-hidden="true">Designer</SkillLabel>
          <SkillLabel aria-hidden="true">Artist</SkillLabel>
          <SkillLabel aria-hidden="true">Creator</SkillLabel>
        </TextRow>
        <TextRow fontSize="1.52vw" color="rgba(255, 255, 255, 0.53)" hideMobile>
          <SkillLabel aria-hidden="true">JavaScript</SkillLabel>
          <SkillLabel aria-hidden="true">React</SkillLabel>
          <SkillLabel aria-hidden="true">Node.js</SkillLabel>
          <SkillLabel aria-hidden="true">Angular</SkillLabel>
          <SkillLabel aria-hidden="true">C#</SkillLabel>
          <SkillLabel aria-hidden="true">Unity</SkillLabel>
          <SkillLabel aria-hidden="true">ASP.NET</SkillLabel>
        </TextRow>
        <TextRow fontSize="1vw" color="rgba(255, 255, 255, .3)" hideMobile>
          <SkillLabel aria-hidden="true">Swift</SkillLabel>
          <SkillLabel aria-hidden="true">jQuery</SkillLabel>
          <SkillLabel aria-hidden="true">Bootstrap</SkillLabel>
          <SkillLabel aria-hidden="true">GatsbyJS</SkillLabel>
          <SkillLabel aria-hidden="true">MongoDB</SkillLabel>
          <SkillLabel aria-hidden="true">Jest</SkillLabel>
          <SkillLabel aria-hidden="true">MySQL</SkillLabel>
          <SkillLabel aria-hidden="true">Sequelize</SkillLabel>
          <SkillLabel aria-hidden="true">Express</SkillLabel>
        </TextRow>
        <TextRow fontSize="0.664vw" color="rgba(255, 255, 255, .13)" hideMobile>
          <SkillLabel aria-hidden="true">Python</SkillLabel>
          <SkillLabel aria-hidden="true">Sci-kit Learn</SkillLabel>
          <SkillLabel aria-hidden="true">SQLite</SkillLabel>
          <SkillLabel aria-hidden="true">MVC</SkillLabel>
          <SkillLabel aria-hidden="true">REST</SkillLabel>
          <SkillLabel aria-hidden="true">GraphQL</SkillLabel>
          <SkillLabel aria-hidden="true">WebSockets</SkillLabel>
          <SkillLabel aria-hidden="true">React Router</SkillLabel>
          <SkillLabel aria-hidden="true">Heroku</SkillLabel>
          <SkillLabel aria-hidden="true">Mongoose</SkillLabel>
          <SkillLabel aria-hidden="true">Webpack</SkillLabel>
        </TextRow>
      </BaseRow>
    </Content>
  </HeroBannerSpace>
);

export default HeroBanner;
