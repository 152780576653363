import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const animationTimeout = 170;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
    transform: 'translate3d(0, 100px, 0)',
    overflow: 'hidden',
  },
  entered: {
    transition: `all ${animationTimeout}ms cubic-bezier(0.0, 0.0, 0.2, 1)`,
    opacity: 1,
    transform: 'translate3d(0, 0px, 0)',
    overflow: 'hidden',
  },
  exiting: {
    transition: `all ${animationTimeout}ms cubic-bezier(0.4, 0.0, 1, 1)`,
    opacity: 0,
    transform: 'translate3d(0, 100px, 0)',
    overflow: 'hidden',
  },
};

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props;
    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: animationTimeout,
            exit: animationTimeout,
          }}
        >
          {status => (
            <div
              style={{
                ...getTransitionStyles[status],
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    );
  }
}

export default Transition;
