import React from 'react';
import styled from '@emotion/styled';
import CutCorner from '../CutCorner';


const ProjectHeader = styled(CutCorner)`
  height: ${props => props.theme.homepagetiles.titleHeight};
  padding: 0.6rem;
  padding-bottom: 0.65rem;
`;

const ProjectTitle = styled.h2`
  text-transform: uppercase;
  margin-bottom: -4px;
  font-weight: 700 !important;
  font-size:  1.4em;
  color: #fff;
  text-decoration: none;
`;

const ProjectType = styled.p`
  margin-top: 0px;
  font-size:  1.2em;    
  color: #fff;
  margin-bottom: -4px;
  text-decoration: none;
`;

export { ProjectHeader, ProjectTitle, ProjectType };
