import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { Transition } from 'react-transition-group';
import { ProjectHeader, ProjectTitle, ProjectType } from './ProjectCard/ProjectCard';


const Wrapper = styled.ul`
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  
  width: 90%;
  margin: auto;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    grid-template-columns: repeat(3, minmax(280px, 1fr));
    width: ${props => props.theme.breakpoints.l};
  }

  padding-top: 1rem;
`;

const Item = styled.div`
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: ${props => props.theme.homepagetiles.ratio};
  }
  
  will-change: transform;
  transition: transform 160ms;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
`;

const Content = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ImageWrapper = styled.div`
  > div {
    bottom: 0;
    left: 0;
    position: absolute !important;
    top: ${props => props.theme.homepagetiles.titleHeight};
    width: 100%;
  }
`;

const ProjectCard = styled.li`
  :focus-within,
  :hover {
    transform: translate3d(0em, -0.2em, 0em);
  }

  transition: 220ms;
  transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
  display: block;

  opacity: ${props => (props.state === 'entering' || props.state === 'entered' ? 1 : 0)};
  transform: translateY(${props => (props.state === 'entering' || props.state === 'entered' ? 0 : 100)}px);
  transition-delay: ${props => (props.state === 'entering' || props.state === 'entered' ? 0 : props.delay)}ms;
  display: block;
`;

const ProjectLink = styled(Link)`
  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  text-decoration: none;
  color: inherit;
`;

const TransitionParent = ({children, delay}) => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, delay);
  }, []);

  return (
    <Transition in={animate} timeout={300 + delay}>
      {state => (
        <>{children({ state, delay })}</>
      )}
    </Transition>
  );
};

const ProjectListing = ({ projectEdges }) => (
  <Wrapper>
    {projectEdges.map((project, index) => (
      <TransitionParent key={project.node.fields.slug} delay={100 + (index * 100)}>
        {({ state, delay }) => (
          <ProjectCard className="animated" state={state} delay={delay}>
            <Item>
              <Content>
                <ImageWrapper>
                  <Img fluid={project.node.frontmatter.cover.childImageSharp.fluid} />
                </ImageWrapper>
                <ProjectHeader>
                  <ProjectTitle>
                    <ProjectLink to={project.node.fields.slug}>
                      {project.node.frontmatter.title}
                    </ProjectLink>
                  </ProjectTitle>
                  <ProjectType>{project.node.frontmatter.service}</ProjectType>
                </ProjectHeader>
              </Content>
            </Item>
          </ProjectCard>
        )}
      </TransitionParent>
    ))}
  </Wrapper>
);

export default ProjectListing;

ProjectListing.propTypes = {
  projectEdges: PropTypes.array.isRequired,
};
